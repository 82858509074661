import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useErrorsStore = defineStore('errors', () => {
  const offline = ref(false)

  const getErrors = computed(() => offline.value)

  const setOfflineError = (value: boolean) => {
    offline.value = value
  }

  function $reset() {
    offline.value = false
  }

  return {
    getErrors,
    setOfflineError,
    $reset
  }
})
