export default [
  {
    path: '',
    name: 'orders.list',
    component: () => import('../views/Orders/OrdersList.vue')
  },
  {
    path: 'replyes',
    name: 'orders.reply',
    component: () => import('../views/Orders/OrdersList.vue')
  },
  {
    path: 'finished',
    name: 'orders.finished',
    component: () => import('../views/Orders/OrdersList.vue')
  }
]
