
import { defineComponent } from 'vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import { useErrorsStore } from '@/stores/errors'

export default defineComponent({
  components: {
    ErrorModal
  },
  data() {
    return {
      store: useErrorsStore()
    }
  },
  computed: {
    offLineOrOnline() {
      return this.store.getErrors
    }
  }
})
