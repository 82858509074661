import { ref, computed, Ref } from 'vue'
import { defineStore } from 'pinia'
import DocsService from '@/services/docs.service'

export const useDocsStore = defineStore('docs', () => {
  const docs = ref([]) as Ref<any>

  const getDocs = computed(() => docs.value)

  const updateDocs = (data: any) => {
    return DocsService.getDocs(data[0], data[1])
      .then((response: any) => {
        if (!data[0].page || data[0].page === 1) {
          docs.value = response.results
        } else {
          docs.value.push(...response.results)
        }
        return Promise.resolve(response)
      })
      .catch((error: any) => {
        return Promise.reject(error)
      })
  }

  function $reset() {
    docs.value = []
  }

  return {
    getDocs,
    updateDocs,
    $reset
  }
})
