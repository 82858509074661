export enum ENotificationsState {
  notSelfEmployed = 'worker_not_self_employee',
  profileIncomplete = 'worker_not_complete_data',
  agreement = 'customer_make_agreement',
  task = 'customer_make_task_order',
  certificate = 'customer_make_acceptance_certificate',
  payment = 'customer_payed',
  aggrementFinish = 'customer_finish_agreement'
}

export type TDocs = {
  category: string
  certificate: number
  company: number
  converting_error_text: string
  created: string
  deeplink: string
  document_certificate_file: string
  end_work_notification_document: number
  end_work_notification_file: string
  exp_date: string
  file: string
  id: number
  ip_address: string
  is_template: boolean
  last_updated: string
  name: string
  not_signed_agreemetns: any
  object: Array<number>
  object_address: string
  omvd: string
  order: {
    id: number
    name: string
  }
  postback_url: string
  project: Array<number>
  slug: string
  start_date: string
  start_work_notification_document: number
  start_work_notification_file: string
  status: string
  suborder: Array<any>
  suborder_change_reason: string
  suborder_result: string
  user_agent: string
  worker: Object
}

export type TOrders = {
  acceptance_certificate_status: Array<any>
  agreement_status: Array<any>
  description: string
  finish_date: string
  id: number
  initial_name: string
  initial_price: string
  initial_quantity: string
  name: string
  order: Object
  price: string
  quantity: string
  returns: Array<any>
  start_date: string
  status: string
  task_order_status: Array<any>
  unit_types: Object
}

export type TNotifications = {
  body: string
  created_at: string
  id: number
  order: number
  payload: {
    document_id: number
    document_type: string
    order_status: string
    required_document_redirect: boolean
    suborder_id: number
    suborder_name: string
    suborder_status: string
  }
  suborder: number
  type: string
  user: number
  viewed: boolean
}
