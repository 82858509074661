
import { defineComponent } from 'vue'
import { UButton } from 'unit-uikit'
import { useErrorsStore } from '@/stores/errors'

export default defineComponent({
  name: 'ErrorModal',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      store: useErrorsStore()
    }
  },
  props: {
    storeError: { type: Boolean, default: false }
  },
  components: {
    UButton
  },
  methods: {
    closeModal() {
      if (this.storeError) {
        this.store.setOfflineError(false)
        return
      }
      this.$emit('update:modelValue', false)
    }
  }
})
