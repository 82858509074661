import { ENotificationsState } from '@/types/api-values'

type suborderOptions = {
  [key: string]: any
}

// Values received from server
export const SERVER_COUNTRIES = {
  RUSSIA: 'РФ',
  BELARUS: 'РБ',
  ARMENIA: 'Армения',
  KAZAKHSTAN: 'Казахстан',
  KYRGYZSTAN: 'Кыргызская Республика',
  UKRAINE: 'Украина',
  LNR: 'Луганская Народная Республика',
  DNR: 'Донецкая Народная Республика'
}

enum EAlertTypes {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success'
}

export const SUBORDER_STATUSES: suborderOptions = {
  awaiting_signing: {
    title: 'Ожидает подписание',
    type: EAlertTypes.info
  },
  in_work: {
    title: 'В работе',
    type: EAlertTypes.info
  },
  active: {
    title: 'В работе',
    type: EAlertTypes.info
  },
  on_check: {
    title: 'На проверке',
    type: EAlertTypes.warning
  },
  act_signing: {
    title: 'Подписание акта',
    type: EAlertTypes.warning
  },
  pending_payment: {
    title: 'Ожидание выплаты',
    type: EAlertTypes.success
  },
  open_dispute: {
    title: 'Открыт спор',
    type: EAlertTypes.error
  },
  finished: {
    title: 'Завершен',
    type: EAlertTypes.success
  },
  cancelled: {
    title: 'Отменен',
    type: EAlertTypes.error
  }
}

export const DOC_STATUSES: suborderOptions = {
  exists: {
    title: 'Действующий',
    type: EAlertTypes.info
  },
  signed: {
    title: 'Подписан',
    type: EAlertTypes.success
  },
  not_signed: {
    title: 'Не подписан',
    type: EAlertTypes.warning
  },
  active: {
    title: 'Действующий',
    type: EAlertTypes.info
  },
  finished: {
    title: 'Завершен',
    type: EAlertTypes.success
  },
  terminated: {
    title: 'Отменён',
    type: EAlertTypes.error
  },
  deleted: {
    title: 'Удалён',
    type: EAlertTypes.error
  },
  declined_by_self_employee: {
    title: 'Отклонен исполнителем',
    type: EAlertTypes.error
  },
  revoked: {
    title: 'Отозван заказчиком',
    type: EAlertTypes.error
  }
}

export const DOC_TYPE: suborderOptions = {
  task_order: {
    title: 'Задание'
  },
  agreement: {
    title: 'Договор'
  },
  acceptance_certificate: {
    title: 'Акт'
  },
  document: {
    title: 'Документ'
  }
}

export const SUBORDER_CHANGE_TYPES = {
  CHANGE: 'change_suborder',
  RETURN: 'return_suborder'
}

export const SUBORDER_CHANGE_REASON = [
  {
    value: 'poor_quality_work',
    label: 'Некачественно выполненные работы'
  },
  {
    value: 'suborder_not_completed',
    label: 'Заказ выполнен не полностью'
  },
  {
    value: 'another_reason',
    label: 'Другая причина'
  }
]

export const NOTIFICATIONS_STATUS = {
  [ENotificationsState.agreement]: 'Подписать договор',
  [ENotificationsState.aggrementFinish]: 'Договор',
  [ENotificationsState.task]: 'Подписать задание',
  [ENotificationsState.certificate]: 'Подписать акт',
  [ENotificationsState.notSelfEmployed]: 'Дополнить ИНН',
  [ENotificationsState.payment]: 'Платёж',
  [ENotificationsState.profileIncomplete]: 'Дополнить данные профиля'
}

export const NOTIFICATIONS_СOLORS = {
  // blue
  [ENotificationsState.agreement]: EAlertTypes.info,
  [ENotificationsState.aggrementFinish]: EAlertTypes.info,
  [ENotificationsState.task]: EAlertTypes.info,
  [ENotificationsState.certificate]: EAlertTypes.info,
  // orange
  [ENotificationsState.notSelfEmployed]: EAlertTypes.error,
  [ENotificationsState.profileIncomplete]: EAlertTypes.error,
  // green
  [ENotificationsState.payment]: EAlertTypes.success
}
