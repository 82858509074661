import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordNormalized
} from 'vue-router'
import Home from '../views/Home.vue'
import profileController from './profileChildren'
import accountController from './accountChildren'
import ordersController from './ordersChildren'
import orderController from './orderChildren'
import docsController from './docsChildren'
import { useAuthStore } from '@/stores/auth'
import { useErrorsStore } from '@/stores/errors'
import { storeToRefs } from 'pinia'

import Page404 from '@/views/404.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
    redirect: () => {
      return '/docs'
    },
    children: [
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/Account/Account.vue'),
        children: accountController,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('../views/Orders/Orders.vue'),
        meta: {
          requiresAuth: true
        },
        children: ordersController
      },
      {
        path: 'order/:id',
        name: 'order',
        component: () => import('../views/Order/Order.vue'),
        meta: {
          requiresAuth: true
        },
        children: orderController
      },
      {
        path: 'docs',
        name: 'docs',
        component: () => import('../views/Docs/Docs.vue'),
        meta: {
          requiresAuth: true
        },
        children: docsController
      },
      // {
      //   path: 'docs/:doc',
      //   name: 'docs.item',
      //   component: () => import('../views/Docs/DocsListView.vue'),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: 'doc/:id',
        name: 'doc',
        component: () => import('../views/Docs/DocItem.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'doc/:id/sign',
        name: 'doc.sign',
        component: () => import('../views/Order/DocSign.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('../views/Notifications/Notifications.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/rules',
        name: 'rules',
        component: () => import('../views/Rules/Rules.vue')
      },
      {
        path: '/aggrement/:id',
        name: 'aggrement',
        component: () => import('../views/Rules/Aggrement.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Registration/Registration.vue')
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('../views/Login/Sms.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile/Profile.vue'),
    children: profileController,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/phone',
    name: 'phone.replace',
    component: () => import('../views/Phone/phone-replace.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/phone/sms',
    name: 'phone.replace.sms',
    component: () => import('../views/Phone/phone-sms.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ref/:id',
    name: 'referral',
    redirect: (from: any) => {
      const id = from.params.id
      setReferral(id)
      return '/login'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: Page404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function setReferral(id: any) {
  const authStore = useAuthStore()
  authStore.setReferral(id)
}

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const errorsStore = useErrorsStore()

  if ('onLine' in window.navigator && window.navigator.onLine === false) {
    errorsStore.setOfflineError(true)
  }
  if (to.matched.some((record: RouteRecordNormalized) => record.meta.requiresAuth)) {
    const authStore = useAuthStore()
    const { getTokenPayload } = storeToRefs(authStore)

    if (authStore.loggedIn) {
      const payload = getTokenPayload.value

      const exp = payload && payload.exp
      if (exp && exp * 1000 < Date.now()) {
        authStore.refreshToken()
      }
      next()
    } else {
      next({
        name: 'login',
        query: { redirectFrom: to.fullPath }
      })
    }
  } else {
    next()
  }
})

export default router
