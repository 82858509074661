import { ref, computed, Ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import { API } from '@/utils/API'
import ProfileService from '@/services/profile.service'
import { SERVER_COUNTRIES } from '@/utils/consts'
import { useAuthStore } from '@/stores/auth'
import { getVnzhArray, formatResidencePermit } from '@/utils/helpers'

/**
 * @param date date with format dd.mm.yyyy
 *
 * @return date with format yyyy-mm-dd
 */
function formatDate(date: string) {
  if (date) {
    const dateArr = date.split('.')
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return ''
}

function formatToDate(date: string) {
  if (date) {
    const dateArr = date.split('-')
    return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`
  }
  return ''
}

export const useProfileStore = defineStore('profile', () => {
  const authStore = useAuthStore()
  const { getTokenPayload } = storeToRefs(authStore)

  const phone = ref('')
  const inn = ref('')
  const email = ref('')
  const middle_name = ref('')
  const date_of_birth = ref('')
  const first_name = ref('')
  const last_name = ref('')
  let citizenship = SERVER_COUNTRIES.RUSSIA
  const sex = ref('male')
  const passport_series = ref('')
  const passport_number = ref('')
  const passport_id_number = ref('')
  const registered_address = ref('')
  const postal_code = ref('')
  const place_of_birth = ref('')
  const date_of_issue = ref('')
  const subdivision_code = ref('')
  const passport_whom_given = ref('')
  const vnzh = ref('')
  const skills = ref([]) as Ref<any>
  const checkedSkills = ref([]) as Ref<any>
  const passport_photo_reversal = ref(null)
  const passport_photo_registered_address = ref(null)
  const selfie = ref(null)
  const card_number = ref('')
  const is_self_employed = ref(false)
  const card_image = ref('')
  const inn_status_checked = ref(true)
  const is_editable = ref(false)
  const complete_data = ref(false)
  const region = ref('')
  const verified = ref(false)

  const getState = computed(() => {
    return {
      phone: phone.value,
      inn: inn.value,
      email: email.value,
      middle_name: middle_name.value,
      date_of_birth: date_of_birth.value,
      first_name: first_name.value,
      last_name: last_name.value,
      citizenship: (citizenship = SERVER_COUNTRIES.RUSSIA),
      sex: (sex.value = 'male'),
      passport_series: passport_series.value,
      passport_number: passport_number.value,
      passport_id_number: passport_id_number.value,
      registered_address: registered_address.value,
      postal_code: postal_code.value,
      place_of_birth: place_of_birth.value,
      date_of_issue: date_of_issue.value,
      subdivision_code: subdivision_code.value,
      passport_whom_given: passport_whom_given.value,
      vnzh: vnzh.value,
      skills: skills.value,
      checkedSkills: checkedSkills.value,
      passport_photo_reversal: passport_photo_reversal.value,
      passport_photo_registered_address: passport_photo_registered_address.value,
      selfie: selfie.value,
      card_number: card_number.value,
      is_self_employed: is_self_employed.value,
      card_image: card_image.value,
      inn_status_checked: inn_status_checked.value,
      is_editable: is_editable.value,
      complete_data: complete_data.value,
      region: region.value,
      verified: verified.value
    }
  })

  const getDataForAddProfile = () => {
    const date = new Date()
    const dateJoined = date.toISOString()
    const result: any = {
      first_name: first_name.value,
      last_name: last_name.value,
      middle_name: middle_name.value,
      email: email.value,
      date_joined: dateJoined,
      verified: false,
      personal_data: {
        region: region.value,
        inner_passport: {
          type: 'Внутренний',
          registered_address: registered_address.value,
          passport_series: passport_series.value,
          passport_number: passport_number.value,
          passport_id_number: passport_id_number.value,
          passport_whom_given: passport_whom_given.value,
          date_of_issue: formatDate(date_of_issue.value),
          place_of_birth: place_of_birth.value,
          passport_photo_reversal: passport_photo_reversal.value,
          passport_photo_registered_address: passport_photo_registered_address.value,
          selfie: selfie.value
        },
        main_specialization: checkedSkills.value[0],
        other_specializations: `${checkedSkills.value.slice(1).join(', ')}`,
        sex: sex.value === 'male' ? 'Муж' : 'Жен',
        citizenship: citizenship,
        date_of_birth: formatDate(date_of_birth.value),
        index: postal_code.value,
        card_number: card_number?.value?.replaceAll(' ', '').slice(0, 18),
        card_image: card_image.value
      }
    }

    if (citizenship === SERVER_COUNTRIES.RUSSIA || citizenship === SERVER_COUNTRIES.DNR) {
      result.personal_data.inner_passport.subdivision_code = subdivision_code?.value?.replaceAll('-', '').trim().slice(0, 6)
    }

    if (vnzh.value && vnzh.value.length > 3) {
      const [vnzhSeries, vnzhNumber] = getVnzhArray(vnzh.value)
      result.personal_data.residence_permit = {
        residence_permit_series: vnzhSeries,
        residence_permit_number: vnzhNumber
      }
    }

    return result
  }

  const getInn = computed(() => inn.value)

  const saveInn = (val: string) => {
    inn.value = val
  }

  const checkInnStatus = (workerId: any) => {
    return ProfileService.checkInnStatus(workerId).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const getWorkerData = (workerId: any) => {
    return ProfileService.getWorkerData(workerId).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const cancelSuborder = (data: any) => {
    return ProfileService.cancelSuborder(data).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const declineDoc = (id: string | number) => {
    return ProfileService.declineDoc(id).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const signDoc = (data: any) => {
    return ProfileService.signDoc(data).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const changePhone = (data: any) => {
    return ProfileService.changePhone(data).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const sendSMS = (phoneNumber: any) => {
    return ProfileService.sendSMS(phoneNumber).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const getPhone = computed(() => phone.value)

  const savePhone = (val: any) => {
    phone.value = val
  }

  const getCardNumber = async () => {
    if (!phone.value) {
      const payload = getTokenPayload.value
      const workerId = payload && payload.user_id
      if (workerId) {
        const res = await axios.get(API.SINGLE_WORKER(workerId))
        saveProfile(res.data)
        return {
          card: card_number.value,
          card_image: card_image.value,
          is_editable: is_editable.value,
          verified: verified.value
        }
      } else {
        console.error('User is not loggined')
      }
    }
    return {
      card: card_number.value,
      card_image: card_image.value,
      is_editable: is_editable.value
    }
  }

  const getProfile = async () => {
    if (!phone.value) {
      const payload = getTokenPayload.value
      const workerId = payload && payload.user_id
      if (workerId) {
        const res = await axios.get(API.SINGLE_WORKER(workerId))
        saveProfile(res.data)
        return getState
      } else {
        console.error('User is not loggined')
      }
    }
    return getState
  }

  const getPassportInfo = async () => {
    if (!phone.value) {
      const payload = getTokenPayload.value
      const workerId = payload && payload.user_id
      if (workerId) {
        const res = await axios.get(API.SINGLE_WORKER(workerId))
        saveProfile(res.data)
        return {
          passport_series: passport_series.value,
          passport_number: passport_number.value,
          passport_id_number: passport_id_number.value,
          registered_address: registered_address.value,
          postal_code: postal_code.value,
          place_of_birth: place_of_birth.value,
          date: date_of_issue.value,
          subdivision_code: subdivision_code.value,
          passport_whom_given: passport_whom_given.value,
          vnzh: vnzh.value,
          citizenship: citizenship,
          is_editable: is_editable.value,
          verified: verified.value
        }
      } else {
        console.error('User is not logged in')
      }
    }
    return {
      passport_series: passport_series.value,
      passport_number: passport_number.value,
      passport_id_number: passport_id_number.value,
      registered_address: registered_address.value,
      postal_code: postal_code.value,
      place_of_birth: place_of_birth.value,
      date: date_of_issue.value,
      subdivision_code: subdivision_code.value,
      passport_whom_given: passport_whom_given.value,
      vnzh: vnzh.value,
      citizenship: citizenship,
      is_editable: is_editable.value,
      verified: verified.value
    }
  }

  const getOnlyPassportInfo = () => {
    return {
      passport_series: passport_series.value,
      passport_number: passport_number.value,
      passport_id_number: passport_id_number.value,
      registered_address: registered_address.value,
      postal_code: postal_code.value,
      place_of_birth: place_of_birth.value,
      date: date_of_issue.value,
      subdivision_code: subdivision_code.value,
      passport_whom_given: passport_whom_given.value,
      vnzh: vnzh.value,
      citizenship: citizenship,
      is_editable: is_editable.value
    }
  }

  const savePassportInfo = (val: any) => {
    passport_series.value = val.passport_series
    passport_number.value = val.passport_number
    passport_id_number.value = val.passport_id_number
    registered_address.value = val.registered_address
    postal_code.value = val.postal_code
    place_of_birth.value = val.place_of_birth
    date_of_issue.value = val.date
    subdivision_code.value = val.subdivision_code
    passport_whom_given.value = val.passport_whom_given
    vnzh.value = val.vnzh
  }

  const getBasicInfo = () => {
    return {
      email: email.value,
      middleName: middle_name.value,
      birthdate: date_of_birth.value,
      name: first_name.value,
      surname: last_name.value,
      gender: sex.value,
      region: region.value,
      is_editable: is_editable.value,
      verified: verified.value
    }
  }

  const saveBasicInfo = (val: any) => {
    email.value = val.email
    middle_name.value = val.middleName
    first_name.value = val.name
    last_name.value = val.surname
    date_of_birth.value = val.birthdate
    sex.value = val.gender
    region.value = val.region
  }

  const getCheckedSkills = () => {
    if (phone.value) {
      return {
        checkedSkills: checkedSkills.value,
        is_editable: is_editable.value
      }
    }
    return false
  }

  const getSkills = async () => {
    if (skills.value.length) {
      return skills.value
    }
    try {
      const res = await axios.get(API.SPECIALIZATIONS)
      skills.value = res.data || []
      return skills.value
    } catch (error) {
      console.error('get specializations error', error)
    }
  }

  const setSkills = (val: any) => {
    checkedSkills.value = val
  }

  const getPhotos = () => {
    return {
      passportReversal: passport_photo_reversal.value,
      passportPhotoRegisteredAddress: passport_photo_registered_address.value,
      passportPhotoSelfie: selfie.value
    }
  }

  const setPhotos = (val: any) => {
    if (val.passportReversal) {
      passport_photo_reversal.value = val.passportReversal
    }
    if (val.passportPhotoRegisteredAddress) {
      passport_photo_registered_address.value = val.passportPhotoRegisteredAddress
    }
    if (val.passportPhotoSelfie) {
      selfie.value = val.passportPhotoSelfie
    }
  }

  const getOnlyCardNumber = () => {
    return {
      card: card_number.value,
      card_image: card_image.value,
      is_editable: is_editable.value
    }
  }

  const setCardNumber = (val: any) => {
    card_number.value = val.card
    card_image.value = val.card_image
  }

  const saveProfile = (profile: any) => {
    const passport = profile.passport_information && profile.passport_information[0]
    const skills = profile.personaldata?.main_specialization
      ? [profile.personaldata.main_specialization, ...profile.personaldata.other_specializations]
      : []
    phone.value = profile.username
    inn.value = profile.personaldata?.inn
    email.value = profile.email
    middle_name.value = profile.middle_name
    date_of_birth.value = formatToDate(profile.personaldata?.date_of_birth)
    first_name.value = profile.first_name
    last_name.value = profile.last_name
    citizenship = profile.personaldata?.citizenship
    sex.value = profile.personaldata?.sex === 'Муж' ? 'male' : 'female'
    passport_series.value = passport?.passport_series
    passport_number.value = passport?.passport_number
    passport_id_number.value = passport?.passport_id_number
    registered_address.value = passport?.registered_address
    postal_code.value = profile.personaldata?.index
    place_of_birth.value = passport?.place_of_birth
    date_of_issue.value = formatToDate(passport?.date_of_issue)
    subdivision_code.value = passport?.subdivision_code
    passport_whom_given.value = passport?.passport_whom_given
    vnzh.value = formatResidencePermit(profile?.residence_permit.length && profile?.residence_permit && profile?.residence_permit[0])
    checkedSkills.value = skills
    passport_photo_reversal.value = passport?.passport_photo_reversal
    passport_photo_registered_address.value = passport?.passport_photo_registered_address
    selfie.value = passport?.selfie
    card_number.value = profile.personaldata?.card_number
    is_self_employed.value = profile.personaldata?.is_self_employed
    card_image.value = profile.personaldata?.card_image
    inn_status_checked.value = profile.personaldata?.inn_status_checked
    is_editable.value = profile.is_editable
    complete_data.value = profile.complete_data
    region.value = profile.region
    verified.value = profile.verified
  }

  const updateProfile = ({ workerId, data }: any) => {
    return ProfileService.update(workerId, data).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const setCitizenship = (val: string) => {
    citizenship = val
  }

  function $reset() {
    phone.value = ''
    inn.value = ''
    email.value = ''
    middle_name.value = ''
    date_of_birth.value = ''
    first_name.value = ''
    last_name.value = ''
    citizenship = SERVER_COUNTRIES.RUSSIA
    sex.value = 'male'
    passport_series.value = ''
    passport_number.value = ''
    passport_id_number.value = ''
    registered_address.value = ''
    postal_code.value = ''
    place_of_birth.value = ''
    date_of_issue.value = ''
    subdivision_code.value = ''
    passport_whom_given.value = ''
    vnzh.value = ''
    skills.value = []
    checkedSkills.value = []
    passport_photo_reversal.value = null
    passport_photo_registered_address.value = null
    selfie.value = null
    card_number.value = ''
    is_self_employed.value = false
    card_image.value = ''
    inn_status_checked.value = true
    is_editable.value = false
    complete_data.value = false
    region.value = ''
  }

  return {
    getState,
    getInn,
    getProfile,
    saveInn,
    getWorkerData,
    cancelSuborder,
    declineDoc,
    signDoc,
    checkInnStatus,
    getDataForAddProfile,
    savePhone,
    changePhone,
    sendSMS,
    getBasicInfo,
    saveBasicInfo,
    getSkills,
    setSkills,
    getCheckedSkills,
    getPhotos,
    setPhotos,
    getPhone,
    getOnlyPassportInfo,
    getOnlyCardNumber,
    setCardNumber,
    getCardNumber,
    savePassportInfo,
    getPassportInfo,
    saveProfile,
    updateProfile,
    setCitizenship,
    $reset
  }
})
