const URL_CLEAR = process.env.VUE_APP_URL
const URL = URL_CLEAR + '/api/v1'

export class API {
  /** @method get */
  static readonly REFRESH_TOKEN = URL_CLEAR + '/api/token/refresh/'

  /** @method get */
  static readonly NOTIFICATIONS = URL_CLEAR + '/api/notifications/'

  static readonly NOTIFICATIONS_WORKER = URL_CLEAR + '/api/notifications/worker'

  /** @method post @method put */
  static readonly SIGNUP = URL + '/users/signup/'

  /** @method post @method put */
  static readonly SIGNUP_OTP = URL + '/users/signup/otp'

  /** @method post */
  static readonly AUTH_USERS = URL + '/users/auth/'

  /** @method post */
  static readonly AUTH_USERS_OTP = URL + '/users/auth/otp/'

  /** @method post */
  static readonly CHECK_CODE = URL + '/users/signup/check_code'

  /** @method get */
  static readonly SPECIALIZATIONS = URL + '/specializations/'

  /** @method get */
  /** @method put */
  /** @method patch */
  static SINGLE_WORKER(id: number | string) {
    return `${URL}/users/worker/${id}/`
  }

  static CANCEL_SUBORDER(id: number | string) {
    return `${URL}/users/worker/${id}/cancel_suborder/`
  }

  /** @method get */
  static readonly GET_ORDERS = URL + '/users/worker/worker_suborder_list/'

  /** @method get */
  static GET_SINGLE_ORDER(id: number | string) {
    return `${URL}/users/worker/${id}/worker_suborder/`
  }

  /** @method get */
  static readonly GET_WORKER_DOCS = URL + '/users/worker/worker_documents/'

  /** @method get */
  static GET_ORDER_DOCS(id: number | string) {
    return `${URL}/users/worker/${id}/worker_document_list/`
  }

  /** @method get */
  static GET_DOC(id: number | string) {
    return `${URL}/users/worker/${id}/document`
  }

  /** @method get @method put */
  static CHECK_INN_STATUS(id: number | string) {
    return `${URL}/users/worker/${id}/check_self_employee_status/`
  }

  /** @method post @method put */
  static FINISH_WORK(id: number | string) {
    return `${URL}/users/worker/${id}/finalize_task/`
  }

  /** @method get */ /** @method patch */ /** @method put */ /** @method delete */
  static SINGLE_OBJECT(id: number | string) {
    return `${URL}/object/${id}/`
  }

  /** @method post */
  static readonly REQUEST_OTP = URL + '/users/change_phone/get_code/'

  /** @method post */
  static readonly CHANGE_PHONE = URL + '/users/change_phone/'

  /** @method POST */ /** @method post */
  static readonly GET_USER_INFO = URL + '/users/me/'

  /** @method patch */
  static DECLINE_DOC(id: number | string) {
    return `${URL}/documents/document/${id}/decline/`
  }
}
