import axios from 'axios'
import { API } from '@/utils/API'

class ProfileService {
  update(workerId: string, data: any) {
    return axios
      .patch(API.SINGLE_WORKER(workerId), data, {
        headers: Object.assign({
          'Content-Type': 'multipart/form-data'
        })
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  cancelSuborder(data: any) {
    return axios
      .patch(API.CANCEL_SUBORDER(data.id), { status: data.status, reason: data.reason })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
  declineDoc(id: string | number) {
    return axios
      .patch(API.DECLINE_DOC(id), {})
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  checkInnStatus(workerId: string) {
    return axios
      .get(API.CHECK_INN_STATUS(workerId))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  getWorkerData(workerId: string) {
    return axios
      .get(API.SINGLE_WORKER(workerId))
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  signDoc(data: any) {
    return axios
      .post(data.deeplink, { phone_number: data.phone_number })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  changePhone(data: any) {
    return axios
      .post(API.CHANGE_PHONE, { phone: data.phone, code: data.code })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  sendSMS(phoneNumber: string) {
    return axios
      .post(API.REQUEST_OTP, { phone: phoneNumber })
      .then((result) => {
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ProfileService()
