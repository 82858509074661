import axios from 'axios'
import { API } from '@/utils/API'
import router from '../router'

function getJWT() {
  const stringJWT = localStorage.getItem('JWT_LK')
  const jwt = stringJWT && JSON.parse(stringJWT)
  return jwt
}

function saveJWT(token: string) {
  localStorage.setItem('JWT_LK', token)
}

function clearJWT() {
  localStorage.removeItem('JWT_LK')
}

class AuthService {
  refreshToken() {
    const jwt = getJWT()
    // eslint-disable-next-line
    const refresh_token = jwt?.refresh_token
    // eslint-disable-next-line
    const access_token = jwt?.access_token

    // eslint-disable-next-line
    if (refresh_token) {
      return axios
        .post(API.REFRESH_TOKEN, {
          refresh: refresh_token,
          old_access: access_token
        })
        .then((response) => {
          // eslint-disable-next-line
          const access_token = response?.data?.access

          // eslint-disable-next-line
          if (access_token) {
            saveJWT(
              JSON.stringify({
                access_token,
                refresh_token
              })
            )
          }

          return response.data
        })
        .then(() => {
          return window.location.reload()
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    } else {
      this.logout()
    }
    return Promise.reject(new Error('no token'))
  }

  login(phone: string) {
    phone = phone?.replace(/-/g, '')

    return axios
      .post(API.AUTH_USERS, {
        phone,
        account_type: 'worker'
      })
      .then((response) => {
        if (response.data.access_token) {
          saveJWT(JSON.stringify(response.data))
        }

        return response.data
      })
  }

  loginConfirm(data: any) {
    const phone = data?.phone?.replace(/-/g, '')

    return axios
      .post(API.AUTH_USERS_OTP, {
        phone,
        account_type: 'worker',
        code: data.code
      })
      .then((response) => {
        if (response.data.access_token) {
          saveJWT(JSON.stringify(response.data))
        }
        return response.data
      })
  }

  logout() {
    clearJWT()
    router.push('/login')
  }

  register(phone: string) {
    phone = phone?.replace(/-/g, '')

    return axios
      .post(API.SIGNUP, {
        phone,
        account_type: 'worker'
      })
      .then((response) => {
        return response.data
      })
  }

  registerConfirm(data: any) {
    const phone = data?.phone?.replace(/-/g, '')

    return axios
      .post(API.SIGNUP_OTP, {
        phone,
        code: data.code,
        account_type: 'worker',
        company_id: data.id
      })
      .then((response) => {
        if (response.data.access_token) {
          saveJWT(JSON.stringify(response.data))
        }

        return response.data
      })
  }
}

export default new AuthService()
