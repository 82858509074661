import axios from 'axios'
import { TDocs, TOrders, TNotifications } from '../types/api-values'

export function getStatusByInn(inn: string, resolve: any, reject: any) {
  const date = new Date()
  const dateStr = date.toISOString().substring(0, 10)
  const url = 'https://statusnpd.nalog.ru/api/v1/tracker/taxpayer_status'
  const data = {
    inn,
    requestDate: dateStr
  }
  axios
    .post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((res: any) => {
      resolve(res)
    })
    .catch((e: any) => {
      reject(e)
    })
}

function buildFormData(formData: any, data: any, parentKey: any) {
  if (data instanceof Array) {
    Object.keys(data).forEach((key: any) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}` : key)
    })
  } else if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

export function jsonToFormData(data: any) {
  const formData = new FormData()
  buildFormData(formData, data, null)
  return formData
}

export function formatPrice(price: number | string) {
  const formatPrice = (+price).toFixed(2)
  return (+formatPrice).toLocaleString(undefined, { minimumFractionDigits: 2 })
}

export function getVnzhArray(vnzh = '') {
  return vnzh.split('№')
}

export function formatResidencePermit(vnzh: any) {
  if (vnzh.residence_permit_series && vnzh.residence_permit_number) {
    return `${vnzh.residence_permit_series}№${vnzh.residence_permit_number}`
  }
  return ''
}

export function normalizeDocs(docs: TDocs): Object {
  return {
    category: docs.category,
    certificate: docs.certificate,
    company: docs.company,
    converting_error_text: docs.converting_error_text,
    created: docs.created,
    deeplink: docs.deeplink,
    document_certificate_file: docs.document_certificate_file,
    end_work_notification_document: docs.end_work_notification_document,
    end_work_notification_file: docs.end_work_notification_file,
    exp_date: docs.exp_date,
    file: docs.file,
    id: docs.id,
    ip_address: docs.ip_address,
    is_template: docs.is_template,
    last_updated: docs.last_updated,
    name: docs.name,
    not_signed_agreemetns: docs.not_signed_agreemetns,
    object: docs.object,
    object_address: docs.object_address,
    omvd: docs.omvd,
    order: docs.order,
    postback_url: docs.postback_url,
    project: docs.project,
    slug: docs.slug,
    start_date: docs.start_date,
    start_work_notification_document: docs.start_work_notification_document,
    start_work_notification_file: docs.start_work_notification_file,
    status: docs.status,
    suborder: docs.suborder,
    suborder_change_reason: docs.suborder_change_reason,
    suborder_result: docs.suborder_result,
    user_agent: docs.user_agent,
    worker: docs.worker
  }
}

export function normalizeOrders(orders: TOrders) {
  return {
    acceptance_certificate_status: orders.acceptance_certificate_status,
    agreement_status: orders.agreement_status,
    description: orders.description,
    finish_date: orders.finish_date,
    id: orders.id,
    initial_name: orders.initial_name,
    initial_price: orders.initial_price,
    initial_quantity: orders.initial_quantity,
    name: orders.name,
    order: orders.order,
    price: orders.price,
    quantity: orders.quantity,
    returns: orders.returns,
    start_date: orders.start_date,
    status: orders.status,
    task_order_status: orders.task_order_status,
    unit_types: orders.unit_types
  }
}

export function normalizeNotifications(notifications: TNotifications) {
  return {
    body: notifications.body,
    created_at: notifications.created_at,
    id: notifications.id,
    order: notifications.order,
    payload: notifications.payload,
    suborder: notifications.suborder,
    type: notifications.type,
    user: notifications.user,
    viewed: notifications.viewed
  }
}
