import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { useAuthStore } from './stores/auth'
import { useErrorsStore } from './stores/errors'

import 'unit-uikit/dist/style.css'
import './assets/styles/style.css'
import './assets/styles/main.postcss'
import axios, { InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { vMask } from 'unit-uikit'

// import * as Sentry from '@sentry/vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const VUE_APP_SENTRY = process.env.VUE_APP_SENTRY

const app = createApp(App)

const dsn = 'https://cf69aa0d17d0461f8750304b4d61e8b4@sentry.theunit.ru/6'

Sentry.init({
  environment: VUE_APP_SENTRY,
  dsn,
  integrations: [new Integrations.Vue({ attachProps: true })]
})

app.config.globalProperties.axios = axios
app.use(createPinia()).use(router).directive('mask', vMask).mount('#app')

const _requestHandler = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const authStore = useAuthStore()

  if (config.headers) {
    config.headers.Authorization = authStore.authHeader
  } else {
    config.headers = <AxiosRequestHeaders>{
      Authorization: authStore.authHeader
    }
  }
  return config
}

axios.interceptors.request.use(
  async (request) => {
    const errorsStore = useErrorsStore()
    const method = request.method
    if (method === 'post' || method === 'put' || method === 'patch') {
      if ('onLine' in window.navigator && window.navigator.onLine === false) {
        errorsStore.setOfflineError(true)
        return Promise.reject(new Error('bad connection'))
      }
    }

    const _request = await _requestHandler(request)
    return _request
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: any) {
    const code = error?.response?.data?.code
    if (code === 'token_not_valid') {
      const authStore = useAuthStore()
      authStore.refreshToken()
    }
    return Promise.reject(error)
  }
)
