export default [
  {
    path: '',
    name: 'docs.list',
    component: () => import('../views/Docs/DocsList.vue')
  },
  {
    path: 'signed',
    name: 'docs.signed',
    component: () => import('../views/Docs/DocsList.vue')
  }
]
