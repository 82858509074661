import { ref, computed, Ref } from 'vue'
import { defineStore } from 'pinia'
import OrdersService from '@/services/orders.service'

export const useOrdersStore = defineStore('orders', () => {
  const orders = ref([]) as Ref<any>

  const getOrders = computed(() => orders.value)

  const getOrder = (id: any) => {
    return OrdersService.getOrder(id).then(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const getOrderDocs = (id: any) => {
    return OrdersService.getOrderDocs(id).then(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const getDocInfo = (id: any) => {
    return OrdersService.getDocInfo(id).then(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const getOrderObject = (id: string) => {
    return OrdersService.getOrderObject(id).then(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const updateOrders = (data: any) => {
    return OrdersService.getOrders(data)
      .then((response) => {
        if (!data.page || data.page === 1) {
          orders.value = response.results
        } else {
          orders.value.push(...response.results)
        }
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  const updateOrdersFinished = (data: any) => {
    return OrdersService.getOrdersFinished(data)
      .then((response) => {
        if (!data.page || data.page === 1) {
          orders.value = response.results
        } else {
          orders.value.push(...response.results)
        }
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  const finishWork = (data: any) => {
    return OrdersService.finishWork(data).then(
      () => {
        return Promise.resolve(data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  function $reset() {
    orders.value = []
  }

  return {
    getOrders,
    getOrder,
    getOrderDocs,
    getDocInfo,
    getOrderObject,
    updateOrders,
    updateOrdersFinished,
    finishWork,
    $reset
  }
})
