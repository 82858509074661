import axios from 'axios'
import { API } from '@/utils/API'
import { jsonToFormData } from '@/utils/helpers'

class OrdersService {
  getOrders({ size = 10, page = 1, orderName = '' }) {
    const queryParams = orderName ? `&order_name=${orderName}` : ''
    return axios
      .get(
        API.GET_ORDERS + `?count=${size}&page=${page}&status=in_work,awaiting_signing,act_signing,pending_payment,on_check` + queryParams
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  getOrdersFinished({ size = 10, page = 1, orderName = '' }) {
    const queryParams = orderName ? `&order_name=${orderName}` : ''
    return axios
      .get(API.GET_ORDERS + `?count=${size}&page=${page}&status=finished,cancelled` + queryParams)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  getOrder(id: any) {
    return axios
      .get(API.GET_SINGLE_ORDER(id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  getOrderDocs(id: any) {
    return axios
      .get(API.GET_ORDER_DOCS(id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error
      })
  }

  getDocInfo(id: any) {
    return axios
      .get(API.GET_DOC(id))
      .then((response) => {
        const { results } = response.data
        return results && results[0]
      })
      .catch((error) => {
        return error
      })
  }

  getOrderObject(id: any) {
    return axios
      .get(API.SINGLE_OBJECT(id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error
      })
  }

  finishWork(data: any) {
    const id = data.id
    const body = data.body
    const documents = body.documents && body.documents.length
    const bodyFormData = documents ? jsonToFormData(body) : body

    if (documents) {
      return axios
        .patch(API.FINISH_WORK(id), bodyFormData, {
          headers: Object.assign({
            'Content-Type': 'multipart/form-data'
          })
        })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          throw new Error(error)
        })
    } else {
      return axios
        .patch(API.FINISH_WORK(id), bodyFormData)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          throw new Error(error)
        })
    }
  }
}

export default new OrdersService()
