export default [
  {
    path: '',
    name: 'profile.inn',
    component: () => import('../views/Profile/Stages/ProfileInn.vue')
  },
  {
    path: 'selfemployed',
    name: 'profile.selfemployed',
    component: () => import('../views/Profile/Stages/ProfileHowTo.vue')
  },
  {
    path: 'citizen',
    name: 'profile.citizen',
    component: () => import('../views/Profile/Stages/ProfileCitizen.vue')
  },
  {
    path: 'info',
    name: 'profile.info',
    component: () => import('../views/Profile/Stages/ProfileInfo.vue')
  },
  {
    path: 'passport',
    name: 'profile.passport',
    component: () => import('../views/Profile/Stages/ProfilePassport.vue')
  },
  {
    path: 'photos',
    name: 'profile.photos',
    component: () => import('../views/Profile/Stages/ProfilePhotos.vue')
  },
  {
    path: 'selfie',
    name: 'profile.selfie',
    component: () => import('../views/Profile/Stages/ProfileSelfie.vue')
  },
  {
    path: 'card',
    name: 'profile.card',
    component: () => import('../views/Profile/Stages/ProfileCard.vue')
  },
  {
    path: 'skills',
    name: 'profile.skills',
    component: () => import('../views/Profile/Stages/ProfileSkills.vue')
  },
  {
    path: 'check',
    name: 'profile.check',
    component: () => import('../views/Profile/Stages/ProfileCheck.vue')
  },
  {
    path: 'ready',
    name: 'profile.ready',
    component: () => import('../views/Profile/Stages/ProfileReady.vue')
  }
]
