export default [
  {
    path: '',
    name: 'order.info',
    component: () => import('../views/Order/OrderInfo.vue')
  },
  {
    path: 'docs',
    name: 'order.docs',
    component: () => import('../views/Order/OrderDocs.vue')
  },
  {
    path: 'docs/check',
    name: 'order.check',
    component: () => import('../views/Order/OrderCheckView.vue')
  },
  {
    path: 'docs/:doc',
    name: 'order.doc',
    component: () => import('../views/Order/OrderDocView.vue')
  },
  {
    path: 'docs/:doc/sign',
    name: 'order.sign',
    component: () => import('../views/Order/OrderDocSign.vue')
  },
  {
    path: 'object',
    name: 'order.object',
    component: () => import('../views/Order/OrderObject.vue')
  },
  {
    path: 'cancel',
    name: 'order.cancel',
    component: () => import('../views/Order/OrderCancel.vue')
  }
]
