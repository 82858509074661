export default [
  {
    path: '',
    name: 'account.menu',
    component: () => import('../views/Account/Pages/Menu.vue')
  },
  {
    path: 'status',
    name: 'account.status',
    component: () => import('../views/Account/Pages/Status.vue')
  },
  {
    path: 'info',
    name: 'account.info',
    component: () => import('../views/Account/Pages/Info.vue')
  },
  {
    path: 'infomain',
    name: 'account.info.main',
    component: () => import('../views/Account/Pages/Infomain.vue')
  },
  {
    path: 'passport',
    name: 'account.info.passport',
    component: () => import('../views/Account/Pages/InfoPassport.vue')
  },
  {
    path: 'skills',
    name: 'account.info.skills',
    component: () => import('../views/Account/Pages/InfoSkills.vue')
  },
  {
    path: 'photos',
    name: 'account.info.photos',
    component: () => import('../views/Account/Pages/InfoPhotos.vue')
  },
  {
    path: 'verification',
    name: 'account.info.verification',
    component: () => import('../views/Account/Pages/InfoSelfie.vue')
  },
  {
    path: 'payment',
    name: 'account.payment',
    component: () => import('../views/Account/Pages/PaymentInfo.vue')
  }
]
