import axios from 'axios'
import { API } from '@/utils/API'

class DocsService {
  getDocs({ size = 10, page = 1 }, currentRoute: string) {
    return axios
      .get(
        API.GET_WORKER_DOCS +
          `?count=${size}&page=${page}&status=${
            currentRoute === 'docs.list' ? 'not_signed' : 'signed,exists,active,finished'
          }&type=acceptance_certificate,agreement,task_order,document`
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error)
      })
  }
}

export default new DocsService()
