import axios from 'axios'
import { API } from '@/utils/API'

class NotificationService {
  getNotifications({ size = 10, page = 1 }) {
    return axios
      .get(API.NOTIFICATIONS_WORKER + `?count=${size}&page=${page}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error
      })
  }
}

export default new NotificationService()
