import { ref, computed, Ref } from 'vue'
import { defineStore } from 'pinia'

import { useDocsStore } from '@/stores/docs'
import { useErrorsStore } from '@/stores/errors'
import { useNotificationsStore } from '@/stores/notifications'
import { useOrdersStore } from '@/stores/orders'
import { useProfileStore } from '@/stores/profile'

import AuthService from '@/services/auth.service'
import jwtDecode from 'jwt-decode'

import { TTokenPayload } from '@/types'

const stringJWT = localStorage.getItem('JWT_LK')
const jwtInitial = stringJWT && JSON.parse(stringJWT)

export const useAuthStore = defineStore('auth', () => {
  const status = ref({
    loggedIn: jwtInitial ? true : false
  }) as any

  const jwt = jwtInitial ? ref(jwtInitial) : (ref(null) as any)

  const referral = ref('') as any

  const loggedIn = computed(() => {
    return jwt.value !== null
  })

  const authHeader = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      return `Bearer ${accessToken}`
    }
    return null
  })

  // const authHeader = computed(() => {
  //   const token = localStorage.getItem('JWT_LK')
  //   const user = token && JSON.parse(token)

  //   if (user && user.access_token) {
  //     return `Bearer ${user.access_token}`
  //   } else {
  //     return {}
  //   }
  // })

  const getReferral = () => {
    if (referral.value) {
      return referral.value
    }
    const ref = localStorage.getItem('REF_ID')
    referral.value = ref || null
    return ref
  }

  const clearRefid = () => {
    referral.value = null
    localStorage.removeItem('REF_ID')
  }

  const setReferral = (id: string) => {
    if (id) {
      localStorage.setItem('REF_ID', id)
      referral.value = id
    }
  }

  const getTokenPayload = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      const _payload = jwtDecode(jwt.value.access_token) as TTokenPayload
      return _payload
    }
    return null
  })

  const updateToken = (val: any) => {
    jwt.value = val
  }

  const refreshToken = () => {
    return AuthService.refreshToken().then(
      (res) => {
        status.loggedIn = true
        jwt.value = res
        return Promise.resolve(res)
      },
      (error) => {
        status.loggedIn = false
        jwt.value = null
        AuthService.logout()
        return Promise.reject(error)
      }
    )
  }
  const login = (user: any) => {
    return AuthService.login(user).then(
      (user) => {
        status.loggedIn = false
        return Promise.resolve(user)
      },
      (error) => {
        status.loggedIn = false
        jwt.value = null
        AuthService.logout()
        return Promise.reject(error)
      }
    )
  }

  const loginConfirm = (user: any) => {
    return AuthService.loginConfirm(user).then(
      (user) => {
        status.loggedIn = true
        jwt.value = user
        return Promise.resolve(user)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const logout = () => {
    useErrorsStore().$reset()
    useNotificationsStore().$reset()
    useDocsStore().$reset()
    useOrdersStore().$reset()
    useProfileStore().$reset()

    referral.value = null
    localStorage.removeItem('REF_ID')
    status.value.loggedIn = false
    jwt.value = null
    AuthService.logout()
  }

  const register = (phone: string) => {
    return AuthService.register(phone).then(
      (response) => {
        status.loggedIn = false
        return Promise.resolve(response.data)
      },
      (error) => {
        status.loggedIn = false
        return Promise.reject(error)
      }
    )
  }

  const registerConfirm = (user: any) => {
    if (referral.value) {
      user.id = referral.value
    }
    return AuthService.registerConfirm(user).then(
      (response: any) => {
        status.loggedIn = true
        jwt.value = response
        return Promise.resolve(response.data)
      },
      (error: any) => {
        status.loggedIn = false
        return Promise.reject(error)
      }
    )
  }

  const tokenExpired = computed(() => {
    const accessToken = jwt.value?.access_token
    if (accessToken) {
      const { exp } = jwtDecode(jwt.value.access_token) as TTokenPayload
      return !!(exp && exp * 1000 < Date.now())
    }
    return true
  })

  return {
    loggedIn,
    authHeader,
    getTokenPayload,
    updateToken,
    getReferral,
    clearRefid,
    setReferral,
    refreshToken,
    login,
    loginConfirm,
    logout,
    register,
    registerConfirm,
    tokenExpired
  }
})
