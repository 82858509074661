import { ref, computed, Ref } from 'vue'
import { defineStore } from 'pinia'
import NotificationService from '@/services/notifications.service'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref([]) as Ref<any>

  const getNotifications = computed(() => notifications.value)

  const updateNotifications = (data: any) => {
    return NotificationService.getNotifications(data).then(
      (response) => {
        if (!data.page || data.page === 1) {
          notifications.value = response.results
        } else {
          notifications.value.push(...response.results)
        }

        return Promise.resolve(response)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  function $reset() {
    notifications.value = []
  }

  return {
    getNotifications,
    updateNotifications,
    $reset
  }
})
